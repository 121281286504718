import classNames from 'classnames';
import { DetailedHTMLProps, LabelHTMLAttributes } from 'react';

type CheckboxLabelProps = DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>

export default function CheckboxLabel({
  className,
  children,
  ...props
}: CheckboxLabelProps) {
  return (
    <label className={classNames('cursor-pointer [&_input]:mt-1 flex space-x-2', className)} {...props}>
      {children}
    </label>
  );
}

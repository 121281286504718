import classNames from 'classnames';
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';

import { getButtonProps } from './Button';

export const FileInput = forwardRef<HTMLInputElement, DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>(
  ({ children, className, ...props }, ref) => {
    const id = props.id || `upload-button-${ Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('')}`;

    return (
      <label
        htmlFor={id}
        className={classNames(
          'inline-block',
          props.disabled ? 'pointer-events-none opacity-50' : 'cursor-pointer',
          getButtonProps({ variant: 'secondary', size: 'sm' }).className,
          className,
        )}
      >
        <>
          {children}
          <input type="file" className="hidden" id={id} {...props} ref={ref} />
        </>
      </label>
    );
  }
);

FileInput.displayName = 'FileInput';

import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

export default function InputDescription({
  className,
  children,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>) {
  return (
    <p className={classNames('text-slate-500 -mt-2 mb-2', className)} {...props}>
      {children}
    </p>
  );
}

import classNames from 'classnames';
import { DetailedHTMLProps, forwardRef, LabelHTMLAttributes } from 'react';

interface RadioProps extends DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
}

export const RadioBox = forwardRef<HTMLLabelElement, RadioProps>(
  ({ size = 'md', disabled, children, ...props }, ref) => {
    const className = classNames(
      'flex w-full cursor-pointer placeholder:text-slate-400 rounded-md border px-3 text-base border-slate-250 leading-snug',
      size === 'sm' && 'min-h-[2rem] py-[0.1875rem]',
      size === 'md' && 'min-h-[2.5rem] py-[0.4375rem]',
      size === 'lg' && 'min-h-[3rem] py-[0.6875rem]',
      !disabled && 'text-slate-800',
      disabled && 'bg-slate-50 pointer-events-none text-slate-500',
      props.className,
    );

    return (
      <label className={className} ref={ref}>
        {children}
      </label>
    );
  }
);

RadioBox.displayName = 'RadioBox';

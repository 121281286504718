import classNames from 'classnames';
import { DetailedHTMLProps, forwardRef, SelectHTMLAttributes } from 'react';

interface SelectProps extends DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  inputSize?: 'sm' | 'md' | 'lg';
  isEmpty?: boolean;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ inputSize = 'md', isEmpty, ...props }, ref) => {
    const className = classNames(
      'block w-full rounded-md border border-slate-250 focus:outline-none focus:ring focus:ring-offset-1 focus:ring-blue-300/30 focus:border-blue-600 px-3 text-base',
      isEmpty ? 'text-slate-400' : 'text-slate-800',
      inputSize === 'sm' && 'h-8',
      inputSize === 'md' && 'h-10',
      inputSize === 'lg' && 'h-12',
      !props.disabled && !isEmpty && 'text-slate-800',
      props.disabled && 'bg-slate-50 text-slate-500 opacity-100',
      props.className,
    );

    return (
      <select {...props} ref={ref} className={className} />
    );
  }
);

Select.displayName = 'Select';

import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useLocale } from '../locale';
import { Pagination } from '../types';
import { LinkButton } from './Button';
import { Icon } from './Icon';

export interface PagerProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  data: Pagination;
}

export function Pager({
  data,
  className,
  ...props
}: PagerProps) {
  const { t } = useTranslation();
  const { formatNumber } = useLocale();

  return (
    <div className={classNames('text-center space-x-2', className)} {...props}>
      <LinkButton
        href={data.links.prev || ''}
        preserveState
        preserveScroll
        variant="tertiary"
        size="xs"
        disabled={!data.links.prev}
        className="!font-medium !text-slate-900"
      >
        <Icon className="mr-1.5">
          <ArrowLeft />
        </Icon>
        {t('shared:pager.prev')}
      </LinkButton>

      <span className="space-x-3">
        {data.meta.current_page > 1 && (
          <>
            <LinkButton
              href={data.links.first || ''}
              preserveState
              preserveScroll
              variant="tertiary"
              size="xs"
              className="font-normal !text-slate-900"
            >
              1
            </LinkButton>
            {data.meta.current_page > 2 && (
              <span className="text-slate-500">
                ⋯
              </span>
            )}
          </>
        )}

        <span className="px-2 py-1 text-sm font-medium rounded-md inlie-block bg-slate-500/10">
          {formatNumber(data.meta.current_page)}
        </span>

        {data.meta.current_page < data.meta.last_page && (
          <>
            {data.meta.current_page + 1 < data.meta.last_page && (
              <span className="text-slate-500">
                ⋯
              </span>
            )}
            <LinkButton
              href={data.links.last || ''}
              preserveState
              preserveScroll
              variant="tertiary"
              size="xs"
              className="font-normal !text-slate-900"
            >
              {formatNumber(data.meta.last_page)}
            </LinkButton>
          </>
        )}
      </span>

      <LinkButton
        href={data.links.next || ''}
        preserveState
        preserveScroll
        variant="tertiary"
        size="xs"
        disabled={!data.links.next}
        className="!font-medium !text-slate-900"
      >
        {t('shared:pager.next')}
        <Icon className="ml-1.5">
          <ArrowRight />
        </Icon>
      </LinkButton>
    </div>
  );
}

import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';

export function useCopy(value: string) {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    copy(value, { format: 'text/plain' });
    setCopied(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [copied]);

  return [handleClick, copied] as [() => void, boolean];
}

import axios from 'axios';
import { useEffect } from 'react';

import { apiRoutes } from './api';
import { AddressFields } from './types';

export function useAddressAutoComplete({
  address,
  onChange,
}: {
  address: Partial<AddressFields>;
  onChange: (address: AddressFields) => void;
}) {
  const delay = import.meta.env.VITEST ? 0 : 300;

  useEffect(() => {
    let subscribed = true;
    let timer: number;

    if (address.country === 'NL' && address.zip_code && address.house_number) {
      timer = window.setTimeout(() => {
        fetchAddress(address).then((response) => {
          if (subscribed) {
            onChange(response.data);
          }
        });
      }, delay);
    }

    return () => {
      subscribed = false;
      window.clearTimeout(timer);
    };
  }, [address.country, address.zip_code, address.house_number]);
}

const fetchAddress = (address: Partial<AddressFields>) => (
  axios.post<Partial<AddressFields>, { data: AddressFields; }>(
    apiRoutes.auto_complete_address,
    address,
  )
);

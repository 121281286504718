import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes, ReactNode, useRef, useState } from 'react';

import useOnClickOutside from '../useOnClickOutside';
import { FadeIn } from './FadeIn';

export default function DropdownMenu({
  trigger,
  children,
  align = 'start',
  className,
}: {
  trigger: ({ onClick, isOpen } : { onClick: () => void, isOpen: boolean; }) => ReactNode;
  children: ReactNode;
  align?: 'start' | 'end';
  className?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => setIsOpen((isOpen) => !isOpen);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(containerRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  return (
    <div className={classNames('relative', isOpen && 'z-40')} ref={containerRef}>
      {trigger({ onClick, isOpen })}
      {isOpen && (
        <FadeIn
          onClick={() => setIsOpen(false)}
          className={classNames(
            'absolute top-10 p-0.5 bg-white border rounded-md shadow-xs max-h-[203px] overflow-y-auto space-y-[1px] min-w-[150px]',
            align === 'start' && 'left-0',
            align === 'end' && 'right-0',
            className,
          )}
        >
          {children}
        </FadeIn>
      )}
    </div>
  );
}

const Item = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={classNames(
        '[&>*]:block [&>*]:w-full [&>*]:px-2 [&>*]:py-1 [&>*]:rounded-sm [&>a]:hover:bg-slate-100 [&>a]:text-slate-800 [&>a]:hover:text-slate-800',
        props.className,
      )}
    />
  );
};

DropdownMenu.Item = Item;

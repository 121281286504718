import { FocusEvent } from 'react';

import { useLocale } from './locale';

export function useCurrencyInput() {
  const { formatCurrency, parseCurrency } = useLocale();

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const parsed = parseCurrency(event.target.value);

      if (!isNaN(parsed)) {
        const min = parseInt(event.target.min) || 0;
        const max = parseInt(event.target.max) || 100000000;
        const newValue = Math.max(Math.min(parsed, max), min);

        event.target.value = formatCurrency(newValue, { style: 'decimal', useGrouping: false });
      } else {
        event.target.value = '';
      }
    }
  };

  const format = (value: number) => {
    return formatCurrency(value, { style: 'decimal', useGrouping: false });
  };

  const parse = (value: string) => {
    const parsed = parseCurrency(value);

    return !isNaN(parsed) ? parsed : null;
  };

  return {
    handleCurrencyBlur: onBlur,
    formatCurrency: format,
    parseCurrency: parse,
  };
}

import 'react-image-crop/dist/ReactCrop.css';

import Compressor from 'compressorjs';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';

const TO_RADIANS = Math.PI / 180;

const canvasPreview = async (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
) => {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  // When changing this, getNormalizedImage must also be changed,
  // because it has effect on the canvas size
  // const pixelRatio = window.devicePixelRatio;
  const pixelRatio = 1;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();
};

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
};

// Make sure that images are not larger than 4096*4096, because that is the maximum canvas size on iOS Safari.
// See https://github.com/jhildenbiddle/canvas-size#test-results
const getNormalizedImage = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 4096,
      maxHeight: 4096,
      success: (normalizedFile) => {
        resolve(normalizedFile as File);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

export default function ImageCropper({
  image,
  onChange,
  aspect,
}: {
  image: File | null,
  onChange: (file: File, image: HTMLImageElement) => void,
  aspect?: number,
}) {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const [imgSrc, setImgSrc] = useState<string>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  useEffect(() => {
    if (image) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || undefined));
      getNormalizedImage(image).then((normalizedFile) => reader.readAsDataURL(normalizedFile));
    }
  }, [image]);

  const onImageLoad = (event: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = event.currentTarget;

    if (aspect) {
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  useEffect(() => {
    const previewCanvas = previewCanvasRef.current;

    if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvas) {
      canvasPreview(
        imgRef.current,
        previewCanvas,
        completedCrop,
      );

      if (image && previewCanvas) {
        previewCanvas.toBlob((blob) => {
          if (blob && imgRef.current) {
            onChange(new File([blob], image.name, { type: image.type }), imgRef.current);
          }
        }, 'image/jpeg', 0.9);
      }
    }
  }, [completedCrop]);

  return (
    <div>
      {imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          keepSelection
          locked
          className="!block"
        >
          <img
            ref={imgRef}
            src={imgSrc}
            className="w-full rounded-sm"
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {completedCrop && (
        <canvas
          ref={previewCanvasRef}
          className="hidden"
          style={{
            objectFit: 'contain',
            width: completedCrop.width,
            height: completedCrop.height,
          }}
        />
      )}
    </div>
  );
}

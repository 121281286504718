import classNames from 'classnames';
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';

type CheckboxProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const className = classNames(
      'w-4 h-4 text-blue-600 border-2 border-slate-250 focus:ring-blue-400/50 focus:ring-2 rounded',
      props?.disabled && 'opacity-50 pointer-events-none',
      props.className,
    );

    return (
      <input type="checkbox" {...props} className={className} ref={ref} />
    );
  }
);

Checkbox.displayName = 'Checkbox';

import { isArray } from 'lodash';
import { DetailedHTMLProps, Fragment, HTMLAttributes, ReactNode } from 'react';

interface DelimitProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  delimiter?: ReactNode;
  children: ReactNode;
}

export default function Delimit({ delimiter, children, ...props }: DelimitProps) {
  return (
    <span {...props}>
      {isArray(children) ? children.filter((child) => child).map((child, index) => (
        <Fragment key={index}>
          {index > 0 && (delimiter || ' · ')}
          {child}
        </Fragment>
      )) : children}
    </span>
  );
}

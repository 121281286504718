import Vapor from 'laravel-vapor';
import { E164Number } from 'libphonenumber-js';
import { FocusEvent, useEffect, useState } from 'react';
import PhoneInput, { Country, parsePhoneNumber } from 'react-phone-number-input';
import enLabels from 'react-phone-number-input/locale/en.json';
import nlLabels from 'react-phone-number-input/locale/nl.json';

import { useLocale } from '../locale';
import { Locale } from '../types';
import { Input } from './Input';

const PhoneNumberInput = ({
  value,
  country,
  onChange,
  onBlur,
  name,
  id,
  ariaInvalid,
}: {
  value: string | null;
  country?: string | null;
  onChange: (result: E164Number) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
  ariaInvalid?: boolean;
}) => {
  const { locale, defaultCountry: localeCountry } = useLocale();

  const initialCountry = country || localeCountry || undefined;
  const [defaultCountry, setDefaultCountry] = useState(initialCountry);

  // Update the default country to the country of the phone number,
  // so that the country remains 'active' when you empty the input.
  useEffect(() => {
    if (value) {
      const phoneNumber = parsePhoneNumber(value);

      if (phoneNumber && phoneNumber.country) {
        setDefaultCountry(phoneNumber.country);
      }
    }
  }, [value]);

  // Update the default country when the given country changes *only* when the input is empty.
  useEffect(() => {
    if (!value) {
      setDefaultCountry(initialCountry);
    }
  }, [value, initialCountry]);

  const labels = locale === Locale.NL ? nlLabels : enLabels;

  return (
    <div>
      <PhoneInput
        value={value || ''}
        onChange={onChange}
        defaultCountry={defaultCountry as Country}
        initialValueFormat="national"
        inputComponent={Input}
        flagUrl={Vapor.asset('images/flags/{XX}.svg')}
        labels={labels}
        onBlur={onBlur}
        name={name}
        smartCaret={false}
        id={id}
        aria-invalid={ariaInvalid}
      />
    </div>
  );
};

export default PhoneNumberInput;

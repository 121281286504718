import { Mark, mergeAttributes } from '@tiptap/core';

export interface ClassNameOptions {
  /**
   * Class names that are allowed.
   */
  allowedClassNames: string[];

  /**
   * HTML attributes to add to the className element.
   * @default {}
   * @example { class: 'foo' }
   */
  HTMLAttributes: Record<string, unknown>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    className: {
      /**
       * Set a className mark
       * @param attributes The className attributes
       * @example editor.commands.setClassName({ class: 'text-xl })
       */
      setClassName: (name: string) => ReturnType
      /**
       * Toggle a className mark
       * @param attributes The className attributes
       * @example editor.commands.toggleClassName({ class: 'text-xl })
       */
      toggleClassName: (className: string) => ReturnType
      /**
       * Unset a className mark
       * @example editor.commands.unsetClassName()
       */
      unsetClassName: () => ReturnType
    }
  }
}

/**
 * This extension allows you to toggle classNames.
 */
export const ClassName = Mark.create<ClassNameOptions>({
  name: 'className',

  addOptions() {
    return {
      allowedClassNames: [],
      HTMLAttributes: {
        class: null,
      },
    };
  },

  addAttributes() {
    return {
      class: {
        default: this.options.HTMLAttributes.class,
      },
    };
  },

  parseHTML() {
    return [{
      tag: 'span[class]',
      getAttrs: (dom) => {
        const className = (dom as HTMLElement).getAttribute('class');

        if (!className || !this.options.allowedClassNames.includes(className)) {
          return false;
        }

        return { class: className };
      },
    }];
  },

  renderHTML({ HTMLAttributes }) {
    if (!this.options.allowedClassNames.includes(HTMLAttributes.class)) {
      // Remove class name.
      return ['span', mergeAttributes(this.options.HTMLAttributes, { ...HTMLAttributes, class: undefined }), 0];
    }

    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setClassName: (className) => ({ chain }) => {
        return chain().setMark(this.name, { class: className }).run();
      },

      toggleClassName: (className) => ({ chain }) => {
        return chain()
          .toggleMark(this.name, { class: className })
          .run();
      },

      unsetClassName: () => ({ chain }) => {
        return chain()
          .unsetMark(this.name)
          .run();
      },
    };
  },
});

import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { Info } from 'react-feather';

import { Icon } from './Icon';

interface InfoMessageProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  icon?: ReactNode;
  variant?: 'neutral' | 'success' | 'alert';
}

export default function InfoMessage({
  icon,
  variant = 'neutral',
  className,
  children,
  ...props
}: InfoMessageProps) {
  const containerClass = {
    neutral: 'bg-blue-100 ',
    success: 'bg-emerald-50',
    alert: 'bg-amber-50',
  }[variant];

  const iconClass = {
    neutral: 'text-blue-700/50',
    success: 'text-emerald-700/50',
    alert: 'text-amber-600/50',
  }[variant];

  return (
    <div
      className={classNames('flex space-x-3 rounded-lg text-slate-800 px-4 py-3', containerClass, className)}
      {...props}
    >
      <Icon className={classNames('mt-1', iconClass)}>
        {icon || <Info strokeWidth={2.5} />}
      </Icon>
      <div>
        {children}
      </div>
    </div>
  );
}

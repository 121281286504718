import { Extension } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import { Plugin } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

export const VariableHighlighter = Extension.create({
  name: 'variableHighlighter',

  addOptions () {
    return {
      variables: [] as string[],
    };
  },

  addProseMirrorPlugins() {
    const variables = this.options.variables;

    return [
      new Plugin({
        state: {
          init(config, { doc }) {
            return findVariables(doc, variables);
          },
          apply(transaction, oldState) {
            return transaction.docChanged ? findVariables(transaction.doc, variables) : oldState;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});

const VARIABLE_REGEX = /{{\s*[a-z0-9_]*\s*}}/gi;

function findVariables (doc: Node, variables: string[] = []): DecorationSet {
  const decorations: Decoration[] = [];

  doc.descendants((node, position) => {
    if (!node.text) {
      return;
    }

    Array.from(node.text.matchAll(VARIABLE_REGEX)).forEach(match => {
      const variable = match[0];
      const index = match.index || 0;
      const from = position + index;
      const to = from + variable.length;

      const isValid = variables.includes(variable.replace(/[{}]*/g, '').trim().toLowerCase());

      const decoration = Decoration.inline(from, to, {
        class: isValid ? 'text-emerald-600' : 'text-red-500',
      });

      decorations.push(decoration);
    });
  });

  return DecorationSet.create(doc, decorations);
}

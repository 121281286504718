import classNames from 'classnames';
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';

type InputWrapperProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const InputWrapper = forwardRef<HTMLDivElement, InputWrapperProps>(({
  children,
  className,
  ...props
}: InputWrapperProps, ref) => {
  return (
    <div
      className={classNames(
        'relative',
        '[&>span]:absolute [&>span]:top-0 [&>span]:bottom-0 [&>span:first-child]:left-0 [&>span:last-child]:right-0 [&>span]:flex [&>span]:items-center [&>span]:px-3',
        '[&>label]:absolute [&>label]:top-0 [&>label]:bottom-0 [&>label:first-child]:left-0 [&>label:last-child]:right-0 [&>label]:flex [&>label]:items-center [&>label]:px-3',
        '[&>button]:absolute [&>button]:top-0 [&>button]:bottom-0 [&>button:first-child]:left-0 [&>button:last-child]:right-0 [&>button]:flex [&>button]:items-center [&>button]:px-3 [&>button:last-child]:rounded-l-none',
        className
      )}
      {...props}
      ref={ref}
    >
      {children}
    </div>
  );
});

InputWrapper.displayName = 'InputWrapper';

import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, MouseEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../Modal/Modal';
import { Button, ButtonProps } from './Button';

interface ConfirmButtonProps extends ButtonProps {
  message?: ReactNode;
}

export const ConfirmButton = forwardRef<HTMLButtonElement, ConfirmButtonProps & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>(
  ({ onClick, message, ...props }, ref) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);
      setShowModal(false);
    };

    return (
      <>
        <Button {...props} onClick={() => setShowModal(true)} ref={ref} />
        <Modal isOpen={showModal} size="sm">
          <div className="space-y-6">
            <div className="space-x-2">
              <h3 className="py-1">
                {t('shared:confirmation.title')}
              </h3>
              {message}
            </div>
            <hr />
            <div className="space-x-2 text-right">
              <Button onClick={() => setShowModal(false)} variant="secondary">
                {t('shared:confirmation.cancel')}
              </Button>
              <Button onClick={handleClick}>
                {t('shared:confirmation.confirm')}
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
);

ConfirmButton.displayName = 'ConfirmButton';

import { FocusEvent } from 'react';

import { useLocale } from './locale';

export function useIntegerInput() {
  const { formatNumber, parseInteger } = useLocale();

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const parsed = parseInteger(event.target.value);

      if (!isNaN(parsed)) {
        const min = parseInt(event.target.min) || 0;
        const max = parseInt(event.target.max) || 100000000;
        const newValue = Math.max(Math.min(parsed, max), min);

        event.target.value = formatNumber(newValue, { useGrouping: false });
      } else {
        event.target.value = '';
      }
    }
  };

  const format = (value: number) => {
    return formatNumber(value, { useGrouping: false });
  };

  const parse = (value: string) => {
    const parsed = parseInteger(value);

    return !isNaN(parsed) ? parsed : null;
  };

  return {
    handleIntegerBlur: onBlur,
    formatInteger: format,
    parseInteger: parse,
  };
}

import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import { FieldError, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { countries } from '../../Backend/countries';
import { zipCodeRegex } from '../locale';
import { AddressFields } from '../types';
import { useAddressAutoComplete } from '../useAddressAutoComplete';
import ErrorMessage from './ErrorMessage';
import { Input } from './Input';
import InputGroup from './InputGroup';
import InputLabel from './InputLabel';
import { Select } from './Select';

const AddressInputs = ({
  form,
  required = true,
}: {
  // eslint-disable-next-line
  form: UseFormReturn<any>,
  required?: boolean;
}) => {
  const { t } = useTranslation();

  const { register, watch, setValue, formState: { errors } } = form;
  const country = watch('country');
  const zipCode = watch('zip_code');
  const houseNumber = watch('house_number');

  const fields = templates[country || ''] || templates.ZZ;
  const sortedCountries = useMemo(() => sortBy(countries, (country) => t(`shared:countries.${country}`)), [t]);

  const inputs = {
    country: (
      <Select
        {...register('country', { required })}
        id="field_country"
        aria-invalid={!!errors.country}
      >
        {!country && (
          <option> </option>
        )}
        {sortedCountries.map((country) => (
          <option value={country} key={country}>
            {t(`shared:countries.${country}`)}
          </option>
        ))}
      </Select>
    ),
    street: (
      <Input
        {...register('street', { required })}
        id="field_street"
        aria-invalid={!!errors.street}
      />
    ),
    house_number: (
      <Input
        {...register('house_number', { required })}
        id="field_house_number"
        aria-invalid={!!errors.house_number}
      />
    ),
    extra_address_line: (
      <Input
        {...register('extra_address_line')}
        id="field_extra_address_line"
        aria-invalid={!!errors.extra_address_line}
      />
    ),
    zip_code: (
      <Input
        {...register('zip_code', {
          required,
          pattern: country === 'NL' ? {
            value: zipCodeRegex.NL, message: t('shared:validation.invalid', { attribute: t('shared:address.zip_code') }),
          } : undefined,
        })}
        id="field_zip_code"
        aria-invalid={!!errors.zip_code}
      />
    ),
    city: (
      <Input
        {...register('city', { required })}
        id="field_city"
        aria-invalid={!!errors.city}
      />
    ),
  };

  useAddressAutoComplete({
    address: {
      country,
      zip_code: zipCode,
      house_number: houseNumber,
    },
    onChange: (address) => {
      const keys = Object.keys(address) as (keyof AddressFields)[];

      keys.forEach((key) => {
        const value = address[key];
        if (value) {
          setValue(key, value, { shouldValidate: true });
        }
      });
    },
  });

  return (
    <div className="space-y-6">
      {fields.map((row) => (
        <div
          className={classNames(
            'grid gap-6',
            row.length === 2 && 'sm:grid-cols-2',
          )}
          key={row.join()}
        >
          {row.map((field) => (
            <InputGroup key={field}>
              <InputLabel
                required={field !== 'extra_address_line' && required}
                valid={!errors[field]}
                htmlFor={`field_${field}`}
              >
                {t(`shared:address.${field}`)}
              </InputLabel>
              {inputs[field]}
              <ErrorMessage error={errors[field] as FieldError} attribute={t(`shared:address.${field}`)} />
            </InputGroup>
          ))}
        </div>
      ))}

    </div>
  );
};

const templates: { [country: string]: (keyof AddressFields)[][]; } = {
  NL: [
    ['country'],
    ['zip_code', 'house_number'],
    ['street'],
    ['city'],
  ],
  FR: [
    ['country'],
    ['house_number', 'street'],
    ['extra_address_line'],
    ['zip_code'],
    ['city'],
  ],
  EN: [
    ['country'],
    ['house_number', 'street'],
    ['extra_address_line'],
    ['city'],
    ['zip_code'],
  ],
  // Other
  ZZ: [
    ['country'],
    ['street', 'house_number'],
    ['extra_address_line'],
    ['zip_code', 'city'],
  ],
};

export default AddressInputs;

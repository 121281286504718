import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import style from './HTML.module.css';

export interface HTMLProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  value: string;
  replacements?: {
    [key: string]: string;
  };
}

export function HTML({
  value,
  replacements,
  className,
  ...props
}: HTMLProps) {
  const replacedValue = replacements
    ? Object.keys(replacements).reduce((value, key) => (
      value.replace(new RegExp(`{{\\s*${key}\\s*}}`, 'gi'), replacements[key])
    ), value)
    : value;

  return (
    <div
      {...props}
      className={classNames(style.html, className)}
      dangerouslySetInnerHTML={{ __html: replacedValue }}
    />
  );
}

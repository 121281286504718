import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { FocusEvent } from 'react';

import { dateFormats, useLocale } from './locale';

export function useDateInput() {
  const locale = useLocale();

  /**
   * Nicely formats the input value.
   */
  const handleDateBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value) {
      let newValue = event.target.value;

      // Remove all characters except 0-9, / and -
      newValue = newValue.replace(/[^0-9/-]/g, '');

      if (locale.locale === 'en') {
        // Accept dashes instead of slashes
        newValue = newValue.replace(/-/g, '/');
      } else if (locale.locale === 'nl') {
        // Accept slashes instead of dashes
        newValue = newValue.replace(/\//g, '-');
      }

      const parsed = locale.parseDate(newValue, dateFormats[locale.locale].input_date_short)
        || locale.parseDate(newValue, dateFormats[locale.locale].input_date);

      event.target.value = parsed ? locale.formatDate(parsed, 'input_date') : '';
    }
  };

  /**
   * Nicely formats the input value.
   */
  const handleTimeBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const parsed = locale.parseDate(event.target.value, dateFormats[locale.locale].input_time);

      event.target.value = parsed ? locale.formatDate(parsed, 'input_time') : '';
    }
  };

  /**
   * Converts a date from the server to a date string that can be shown to the user.
   */
  const formatDate = (value: string) => {
    return locale.formatDate(value, 'input_date');
  };

  /**
   * Converts a date from the server to a time string that can be shown to the user.
   */
  const formatTime = (value: string) => {
    return locale.formatDate(value, 'input_time');
  };

  /**
   * Converts a date in the form state to an ISO date string (UTC).
   *
   * Without time, the time is set to the start of the day (in UTC).
   */
  const parseDate = (date: string, time?: string | null) => {
    if (!time) {
      const parsed = locale.parseDate(date, dateFormats[locale.locale].input_date);

      return parsed ? format(fromZonedTime(parsed, locale.timeZone), 'yyyy-MM-dd') + ' 00:00:00' : null;
    }

    const parsed = locale.parseDate(`${date} ${time || ''}`.trim(), dateFormats[locale.locale].input_date_time);

    return parsed ? fromZonedTime(parsed, locale.timeZone).toISOString() : null;
  };

  return {
    handleDateBlur,
    handleTimeBlur,
    formatDate,
    formatTime,
    parseDate,
  };
}

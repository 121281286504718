import { useLocale } from '../locale';

export interface DateRangeProps {
  start: string;
  end?: string | null;
}

/**
 * @param start Start date string.
 * @param end End date string.
 */
const DateRange = ({ start, end }: DateRangeProps) => {
  const { formatDate } = useLocale();

  const startDate = new Date(start);
  const endDate = end ? new Date(end) : null;
  const sameDay = endDate && formatDate(startDate, 'display_date') === formatDate(endDate, 'display_date');

  const startTime = formatDate(startDate, 'display_time');
  const endTime = endDate ? formatDate(endDate, 'display_time') : null;
  const sameTime = startTime === endTime;

  if (sameDay) {
    return (
      <>
        {formatDate(startDate, 'display_date')}
        {sameTime && startTime !== '00:00' && (
          `, ${formatDate(startDate, 'display_time')}`
        )}
        {!sameTime && (
          <>
            {', '}
            {formatDate(startDate, 'display_time')}
            <span className="opacity-50"> &ndash; </span>
            {formatDate(endDate, 'display_time')}
          </>
        )}
      </>
    );
  }

  if (endDate) {
    const format = startTime === '00:00' && endTime === '00:00' ? 'display_date' : 'display_date_time';

    return (
      <>
        {formatDate(startDate, format)}
        <span className="opacity-50"> &ndash; </span>
        {formatDate(endDate, format)}
      </>
    );
  }

  const format = startTime === '00:00' ? 'display_date' : 'display_date_time';

  return <>{formatDate(startDate, format)}</>;
};

export default DateRange;
